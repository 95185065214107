<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="message" rules="required" v-slot="{ errors}">
                        <b-form-group :label="$t('message')">
                            <b-form-textarea v-model="message"
                                :state="errors[0] ? false : null"
                                />
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    <b-button variant="primary" class="mt-0" @click="send">
                        {{ $t('send') }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'

    export default {
        name: "BulkMessageForm",
        components: {
            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number,
                default: null
            },
            formData: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                message: null,
            }
        },
        methods: {
            async send() {
                const valid = await this.$refs.formModalValidate.validate()
                if(!valid) {
                    return
                }
                this.$emit('send', this.message)

            }
        }
    }
</script>
